<template>
    <RecordViewWrapper>
        <sdPageHeader title="Clients Orders" class="custom-header-full">
        </sdPageHeader>
        <Main>
            <a-row :gutter="15">
                <a-col class="w-100" :md="24">
                    <GlobalFilters hasNumberFilter @onFilter="filter"></GlobalFilters>
                </a-col>
            </a-row>
            <a-row :gutter="15">
                <a-col class="w-100" :md="24">
                    <sdCards headless>
                        <div v-if="isLoading" class="spin">
                            <a-spin />
                        </div>

                        <div v-else>
                            <TableWrapper class="table-data-view table-responsive">
                                <a-table :loading="isLoading" @change="onHandleTableChange" :dataSource="dataSource"
                                    :columns="columns">

                                    <template #status="{ record }">
                                        <p v-if="record.sale.saleStatus === 1">Draft</p>
                                        <p v-if="record.sale.saleStatus === 2">Confirmed</p>
                                        <p v-if="record.sale.saleStatus === 3">Canceled</p>
                                    </template>
                                </a-table>
                            </TableWrapper>
                        </div>
                    </sdCards>
                </a-col>
            </a-row>
        </Main>
    </RecordViewWrapper>
</template>

<script>
import { RecordViewWrapper } from "../../view/crud/axios/style";
import { Main, TableWrapper } from "../styled";
import { useStore } from "vuex";
import { useFileDownload } from '@/composable/useFileDownload';
import { getCurrentInstance, defineAsyncComponent, computed, onMounted, ref, reactive, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { notification } from "ant-design-vue";
import { DataService } from "@/config/dataService/dataService";
import moment from "moment";
const GlobalFilters = defineAsyncComponent(() => import("@/components/businessComponents/GlobalFilters.vue"));

const columns = [
    {
        title: "Partner",
        dataIndex: "partner",
        key: "partner",
    },
    {
        title: "Date",
        dataIndex: "date",
        key: "date",
        sorter: true,
    },
    {
        title: "Number",
        dataIndex: "sale.number",
        key: "sale.number",
    },
    {
        title: "Status",
        key: "status",
        slots: {
            customRender: "status",
        },
    },
    {
        title: "File",
        dataIndex: "file",
        key: "file",
    },
    {
        title: "Actions",
        dataIndex: "action",
        key: "action",
        width: "90px",
    },
];

const ViewPage = {
    name: "ViewPage",
    components: { GlobalFilters, RecordViewWrapper, Main, TableWrapper },
    setup() {
        const { downloadFile } = useFileDownload();
        const { state, dispatch } = useStore();
        const SaleApi = computed(() => state.saleApi.data);
        const isLoading = computed(() => state.saleApi.loading);
        const selectedRowKeys = ref([]);
        const { params } = useRoute();
        const router = useRouter();
        const saleType = 1;
        const AcknowledgementFiles = 8;
        const appContext = getCurrentInstance().appContext;
        const userHasPermission = appContext.config.globalProperties.$userHasPermission;
        const pagination = reactive({
            salePartenaire: "",
            saleDate: "",
            saleNumber: "",
            saleDateFrom: "",
            saleDateTo: "",
            hasAcknowledgement: true,
            addFiles: true,
            saleType: 1
        });
        const deletedItems = ref(false);


        onMounted(() => {
            dispatch("emptyProductSaleData");

            dispatch("saleGetAllData", pagination);
        });
        watch(saleType, () => {
            alert("chnaged");
        });
        const handleDelete = (id) => {
            const confirm = window.confirm("Are you sure delete this?");
            if (confirm) {
                dispatch("saleApiDataDelete", id);
            }
            return false;
        };
        const goToSale = (sale) => {
            router.push(`/sale/edit-sale-order/${sale.id}`);
        };
        const restorItem = (id) => {
            const confirm = window.confirm("Are you sure restore this?");
            if (confirm) {
                dispatch("saleApiDataRestore", id);
            }
            return false;
        };

        const formState = reactive({
            searchItem: "",
        });

        const onHandleSearch = () => {
            dispatch("contractDataSearch", formState.searchItem);
        };

        const getAcknowledgementFileList = (files) => {
            return files.filter(
                (file) => file.fileType == AcknowledgementFiles
            );
        };

        const dataSource = computed(() =>
            SaleApi.value && SaleApi.value.length
                ? SaleApi.value.map((sale, key) => ({
                    key: key + 1,
                    sale,
                    date: <div class="">{moment(sale.salesDate).format("DD/MM/YYYY")}</div>,
                    partner: (
                        <div className="partner">
                            {sale.partnerName}
                            {sale.totalTTC < 0 && <div className="ristorno">(Ristorno)</div>}
                        </div>
                    ),
                    isValid: sale.isValid ? "Valid" : "Need validation",
                    file: getAcknowledgementFileList(sale.files).length > 0 ? (
                        <a-button
                            title="Acknowledgement"
                            class="sliders"
                            onClick={() => downloadFiles(getAcknowledgementFileList(sale.files))}
                        >
                            <sdFeatherIcons type="sliders" size={14} />
                        </a-button>
                    ) : null,
                    action: generateSaleActions(sale),
                }))
                : []
        );

        const downloadFiles = async (filelist) => {
            await filelist.reduce(async (previousPromise, file) => {
                await previousPromise;

                return new Promise((resolve) => {
                    downloadFile(file)
                        .then(() => resolve());
                });
            }, Promise.resolve());
        };
        const generateSaleActions = (sale) => {
            if (!deletedItems.value) {
                return (
                    <div className="table-actions">
                        <a-button class="edit" onClick={() => goToSale(sale)}>
                            <sdFeatherIcons type="eye" size={14} />
                        </a-button>
                        {userHasPermission('Permissions.Sale.CreateInvoice') && saleType.value !== 3 && (
                            <a-button onClick={() => generateInvoice(sale.id)}>
                                <sdFeatherIcons type="file-text" size={14} />
                            </a-button>
                        )}
                        {userHasPermission('Permissions.Sale.Delete') && (
                            <a-button class="delete" onClick={() => handleDelete(sale)}>
                                <sdFeatherIcons type="trash-2" size={14} />
                            </a-button>
                        )}
                    </div>
                );
            } else {
                return (
                    <div className="table-actions">
                        <a-button class="delete" onClick={() => restorItem(sale)}>
                            <sdFeatherIcons type="refresh-ccw" size={14} />
                        </a-button>
                    </div>
                );
            }
        };


        const filter = (filters) => {
            pagination.salePartenaire = filters.partnerId ?? "";
            pagination.saleNumber = filters.number ?? "";
            pagination.saleDateFrom = filters.dateStart ? moment(filters.dateStart).format('YYYY/MM/DD') : "";
            pagination.saleDateTo = filters.dateEnd ? moment(filters.dateEnd).format('YYYY/MM/DD') : "";
            dispatch("saleGetAllData", pagination);
        }

        // Pagination Logic
        const switchDeletedView = () => {
            deletedItems.value = !deletedItems.value;
            pagination.salePartenaire = "";
            pagination.saleNumber = "";
            pagination.hasAcknowledgement = true;
            pagination.addFiles = true;
            pagination.saleDateFrom = "";
            pagination.saleDateTo = "";
            if (deletedItems.value) {
                dispatch("saleGetAllDeletedData", pagination);
            } else {
                dispatch("saleGetAllData", pagination);
            }
        };

        const onHandleTableChange = (TablePagination, filters, sorter) => {
            console.log("sorter", sorter);

        };

        const generateInvoice = async (SaleId) => {
            try {
                const response = await DataService.post(
                    "api/Sale/CreateInvoice", { "saleId": SaleId }
                );
                router.push(`/sale/edit-invoice-order/${response.data.id}`);

            } catch (err) {
                console.log("error", err);
                notification.error({
                    message: err.message,
                });
            }
        };
        // const validateInvoice = (SaleId) => {
        //   dispatch("validateInvoice", SaleId)
        // }

        return {
            generateInvoice,
            filter,
            isLoading,
            selectedRowKeys,
            onHandleSearch,
            handleDelete,
            dataSource,
            columns,
            formState,
            params, switchDeletedView,
            deletedItems, onHandleTableChange
        };
    },
};

export default ViewPage;
</script>

<style>
.partner {
    display: flex;
}

.ristorno {
    color: #f5222d;
}
</style>
